import { Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { Json } from '../../components/Json';

export const PageCloudFunctions = () => {
  const [fnName, setFnName] = useState('');
  const [data, setData] = useState('{\n  \n}');
  const [res, setRes] = useState<any>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Section>
        <FlexContainerVertical fullWidth spacing={2}>
          <TextField
            label="Function Name"
            fullWidth
            spellCheck="false"
            value={fnName}
            onChange={(ev) => setFnName(ev.target.value)}
            variant="outlined"
          />
          <TextField
            label="Data (JSON)"
            key="query"
            fullWidth
            spellCheck={false}
            inputProps={{
              style: {
                fontFamily: 'monospace'
              }
            }}
            value={data}
            onChange={(ev) => {
              setData(ev.target.value);
            }}
            multiline
            rows={25}
            variant="outlined"
          />
          <FlexContainer justifyContent="space-between" fullWidth>
            <Button
              onClick={() => setData(JSON.stringify(JSON.parse(data), null, 2))}
              variant="outlined"
              color="primary"
            >
              Format
            </Button>

            <FlexContainer justifyContent="flex-end" fullWidth>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Publishing..."
                onClick={async () => {
                  try {
                    const res = await callFirebaseFunction(
                      fnName,
                      JSON.parse(data)
                    );
                    console.log(`Result for ${fnName}`, res);
                    setRes(res);
                    enqueueSnackbar('Published successfully');
                  } catch (err) {
                    console.log('ERR', err);
                    setRes({ error: err });
                    enqueueSnackbar(`Something went wrong - ${err?.message}`, {
                      variant: 'error'
                    });
                  }
                }}
              >
                Call
              </ButtonWithPromise>
            </FlexContainer>
          </FlexContainer>
        </FlexContainerVertical>
      </Section>
      <Section>
        {res !== undefined ? <Json data={res} fullWidth /> : null}
      </Section>
    </Page>
  );
};
